import React, { useRef } from 'react'

import useUser from '../../../context/userContext'

import Modal from '../../UI/Modal'

import './UserFormModal.scss'

export default function UserForm() {
	const nameInputRef = useRef<HTMLInputElement>(null)

	const { setName } = useUser()

	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()

		const newName = nameInputRef.current?.value
		if (newName) setName(newName)
	}
	return (
		<Modal
			title="Użytkownik"
			className="user-form-modal">
			<form onSubmit={onSubmit}>
				<label htmlFor="name-input-input">Podaj imię</label>
				<input
					ref={nameInputRef}
					type="text"
					id="name-input-input"></input>
				<button type="submit">Zapisz</button>
			</form>
		</Modal>
	)
}
