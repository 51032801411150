import React from 'react'

import useCarriage from '../../context/carriageContext'
import useUser from '../../context/userContext'

import './Template.scss'

export const templateContentRef = React.createRef<HTMLDivElement>()

export default function Template() {
	const { carriages } = useCarriage()
	const { name } = useUser()

	return (
		<div
			id="template-content"
			ref={templateContentRef}>
			<h1>{name ?? 'User'}</h1>
			<ul>
				{carriages.map((carriage) => {
					const carriageItems = carriage.items.map((item) => (
						<li
							key={item.id}
							style={item.style}>
							{item.value}
						</li>
					))
					return (
						<li key={carriage.carriageId}>
							<span>{carriage.carriageName}</span>
							<ul>{carriageItems}</ul>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
