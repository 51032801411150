import React from 'react'

import './Tree.scss'

export default function Tree() {
	const minWidth = 2.5
	const maxWidth = 3.5
	const minHeight = 50
	const maxHeight = 80
	const minBrightness = 75
	const maxBrightness = 125

	const height = Math.floor(Math.random() * (maxHeight - minHeight)) + minHeight
	const width = Math.random() * (maxWidth - minWidth) + minWidth
	const brightness = Math.floor(Math.random() * (maxBrightness - minBrightness)) + minBrightness

	const style = {
		'--height': `${height}%`,
		'--width': `${width}rem`,
		'--brightness': `${brightness}%`
	} as React.CSSProperties
	return (
		<div
			className="tree"
			style={style}></div>
	)
}
