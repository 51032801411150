import React, { useCallback, useState } from 'react'

import { carriageContext } from './carriageContext'
import { setInArray } from '../utils'

import { ChildrenProps } from './types'
import { Carriage } from './carriageContext.type'
import { Item } from './stationContext.type'

export default function CarriageContextWrapper(props: ChildrenProps) {
	const [carriages, setCarriages] = useState<Carriage[]>([])

	function addItem(item: Item, id: string) {
		setCarriages((carriages) => {
			const carriageIndex = carriages.findIndex((carriage) => carriage.carriageId === id)
			return setInArray(carriages, carriageIndex, (carriage) => ({
				...carriage,
				items: [...carriage.items, item]
			}))
		})
	}
	const setCarriagesItems = useCallback((items: Carriage[]) => {
		setCarriages((value) => (value = items))
	}, [])
	return (
		<carriageContext.Provider value={{ carriages, setCarriages: setCarriagesItems, addItem }}>
			{props.children}
		</carriageContext.Provider>
	)
}
