import React, { useContext } from 'react'

import { CarriageContext } from './carriageContext.type'

export const carriageContext = React.createContext<CarriageContext>({
	carriages: [],
	addItem: () => {},
	setCarriages: () => {}
})

export default function useCarriage() {
	return useContext(carriageContext)
}
