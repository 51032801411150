import React from 'react'
import anime from 'animejs'

import useStation from '../../../context/stationContext'
import { itemAppearance } from '../../../animation'

import './StationButtons.scss'

export default function StationButtons() {
	const { addCarriageItem, station } = useStation()

	async function onCarriageItemAdded(event: React.MouseEvent<HTMLButtonElement>) {
		event.currentTarget.disabled = true
		const itemId = event.currentTarget.dataset.itemId
		if (itemId) {
			await anime(itemAppearance.easeOut(event.currentTarget)).finished
			addCarriageItem(itemId)
		}
	}

	return (
		<ul className="station-buttons">
			{station?.stationItems.map((item) => (
				<li key={item.id}>
					<button
						onClick={onCarriageItemAdded}
						data-item-id={item.id}
						style={item.style}>
						{item.value}
					</button>
				</li>
			))}
		</ul>
	)
}
