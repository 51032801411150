import useRefs from '../../../context/refsContext'
import useStation from '../../../context/stationContext'
import useCarriage from '../../../context/carriageContext'
import { downloadResult } from '../../../utils/download'

import StationForm from './StationForm'
import StationButtons from './StationButtons'
import Modal from '../../UI/Modal'

import './Station.scss'

export default function Station() {
	const refs = useRefs()
	const { station } = useStation()
	const { carriages } = useCarriage()

	let modalTitle = `Stacja: ${station?.stationName}`
	let modalContent = (
		<>
			<StationForm />
			<StationButtons />
		</>
	)
	if (!station) {
		modalTitle = 'Stacja końcowa'
		modalContent = (
			<>
				<ul className="station-summary-list">
					{carriages.map((carriage) => (
						<li key={carriage.carriageId}>
							<span>{carriage.carriageName}</span>
							<ul>
								{carriage.items.map((item) => (
									<li
										key={item.id}
										style={item.style}>
										{item.value}
									</li>
								))}
							</ul>
						</li>
					))}
				</ul>
				<button
					onClick={downloadResult}
					className="button">
					Download
				</button>
			</>
		)
	}
	return (
		<div
			className="station"
			ref={refs.station}>
			<object
				data={`${window.location.origin}/assets/station.svg`}
				type="image/svg+xml"
				className="station-wrapper">
				Station
			</object>
			<Modal
				ref={refs.stationModal}
				title={modalTitle}
				className="station-modal">
				{modalContent}
			</Modal>
		</div>
	)
}
