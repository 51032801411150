import React from 'react'

import useRefs from '../../../context/refsContext'

import './Train.scss'

export default function Train(props: any) {
	const refs = useRefs()

	return (
		<div
			className="train"
			ref={refs.train}>
			<object
				data={window.location.origin + '/assets/train.svg'}
				type="image/svg+xml">
				Train
			</object>
			{props.children}
		</div>
	)
}
