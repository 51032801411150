import React from 'react'

import useStation from '../../../context/stationContext'

import { CarriageProps } from './Carriage.type'

import CarriageItem from './CarriageItem'

import './Carriage.scss'

export default function Carriage(props: CarriageProps) {
	const { getCarriage } = useStation()
	return (
		<div
			className="carriage"
			data-carriage-id={props.carriageId}>
			<div className="items">
				{getCarriage(props.carriageId)?.items.map((item) => {
					return (
						<CarriageItem
							style={item.style}
							key={item.id}
							data-id={item.id}
						/>
					)
				})}
			</div>
			<span>{props.name}</span>
			<object
				data={`${window.location.origin}/assets/carriage.svg`}
				type="image/svg+xml">
				Carriage
			</object>
		</div>
	)
}
