import React, { useRef } from 'react'

import useStation from '../../../context/stationContext'
import { eventEmitter } from '../../../animation'

import './StationForm.scss'

export default function StationForm() {
	const inputRef = useRef<HTMLTextAreaElement>(null)
	const { addStationItem, nextStation } = useStation()

	function moveToNextStation() {
		eventEmitter.emit('next-station')
		eventEmitter.once('station-hidden', nextStation)
	}
	function onStationItemAdded() {
		const value = inputRef.current?.value
		if (value) {
			addStationItem(value)
			inputRef.current.value = ''
		}
	}

	return (
		<form className="station-form">
			<button
				onClick={moveToNextStation}
				type="button">
				Następna stacja
			</button>
			<div>
				<textarea ref={inputRef}></textarea>
				<button
					onClick={onStationItemAdded}
					type="button">
					Dodaj
				</button>
			</div>
		</form>
	)
}
