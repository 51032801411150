import React from 'react'

import { refsContext } from './refsContext'

import { ChildrenProps } from './types'
import { Refs } from './refsContext.type'

const trainRef = React.createRef<HTMLDivElement>()
const userRef = React.createRef<HTMLDivElement>()
const trackRef = React.createRef<HTMLDivElement>()
const stationRef = React.createRef<HTMLDivElement>()
const treesRef = React.createRef<HTMLDivElement>()
const contentRef = React.createRef<HTMLDivElement>()
const stationModalRef = React.createRef<HTMLDivElement>()
const backgroundRef = React.createRef<HTMLDivElement>()

export default function RefsContextWrapper(props: ChildrenProps) {
	const refs: Refs = {
		train: trainRef,
		user: userRef,
		track: trackRef,
		station: stationRef,
		trees: treesRef,
		content: contentRef,
		stationModal: stationModalRef,
		background: backgroundRef
	}
	return <refsContext.Provider value={refs}>{props.children}</refsContext.Provider>
}
