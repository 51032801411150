import React, { useEffect } from 'react'

import useRefs from './context/refsContext'
import { init } from './animation'
import { checkObjectsProps } from './utils'
import useStation from './context/stationContext'
import useUser from './context/userContext'

import { Refs } from './context/refsContext.type'

import ContentWrapper from './components/ContentWrapper/ContentWrapper'
import TreesWrapper from './components/TreesWrapper/TreesWrapper'
import Station from './components/ContentWrapper/Objects/Station'
import Template from './components/UI/Template'
import UserFormModal from './components/ContentWrapper/User/UserFormModal'

function App() {
	const refs = useRefs()
	const { stations } = useStation()
	const { userIsSet } = useUser()

	useEffect(() => {
		if (checkObjectsProps(refs) && userIsSet) {
			init(refs as Required<Refs>, stations)
		}
	}, [refs, stations, userIsSet])

	if (!userIsSet) {
		return <UserFormModal />
	}
	return (
		<>
			<Station />
			<ContentWrapper />
			<TreesWrapper />
			<Template />
		</>
	)
}

export default App
