import React, { useState } from 'react'

import { userContext } from './userContext'

import { ChildrenProps } from './types'

export default function UserContextWrapper(props: ChildrenProps) {
	const [name, setName] = useState<string | null>(null)
	const userIsSet = name !== null

	function setUserName(name: string) {
		setName((n) => (n = name))
	}
	return <userContext.Provider value={{ userIsSet, name, setName: setUserName }}>{props.children}</userContext.Provider>
}
