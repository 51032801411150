import React from 'react'
import { createPortal } from 'react-dom'

import { ModalProps } from './Modal.type'

import './Modal.scss'

export default React.forwardRef<HTMLDivElement, ModalProps>(function Modal(props, ref) {
	const root = document.querySelector('#root')
	if (!root) return <p>Couldn't render modal</p>
	return createPortal(
		<div
			ref={ref}
			className={`modal ${props.className ? props.className : ''}`}>
			<span className="modal-title">{props.title}</span>
			<div className="modal-content">{props.children}</div>
		</div>,
		root
	)
})
