import React from 'react'
import ReactDOM from 'react-dom/client'

import RefsContextWrapper from './context/RefsContextWrapper'
import StationContextWrapper from './context/StationContextWrapper'
import CarriageContextWrapper from './context/CarriageContextWrapper'
import UserContextWrapper from './context/UserContextWrapper'

import App from './App'

import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<RefsContextWrapper>
			<CarriageContextWrapper>
				<StationContextWrapper>
					<UserContextWrapper>
						<App />
					</UserContextWrapper>
				</StationContextWrapper>
			</CarriageContextWrapper>
		</RefsContextWrapper>
	</React.StrictMode>
)
