import React from 'react'

import useRefs from '../../context/refsContext'

import Tree from './Tree'

import './TreesWrapper.scss'

export default function TreesWrapper() {
	const refs = useRefs()

	const treesCount = 10

	return (
		<div
			className="trees"
			ref={refs.trees}>
			{new Array(treesCount).fill(null).map((_, i) => (
				<Tree key={i} />
			))}
		</div>
	)
}
