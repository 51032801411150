import React from 'react'

import useRefs from '../../../context/refsContext'

import './User.scss'

export default function User() {
	const refs = useRefs()
	return (
		<div
			className="user"
			ref={refs.user}>
			<object
				data={`${window.location.origin}/assets/user.svg`}
				type="image/svg+xml">
				User
			</object>
		</div>
	)
}
