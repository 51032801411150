import React from 'react'

import useRefs from '../../../context/refsContext'

import { BackgroundProps } from './Background.type'

import './Background.scss'

export default function Background(props: BackgroundProps) {
	const refs = useRefs()
	const layerElements = (() => {
		const layers = []
		for (let i = 0; i < props.layers; i++) {
			layers.push(
				<div
					key={i}
					className="layer"
					style={{
						filter: `brightness(${100 - i * (props.brightnessChange ?? 5)}%)`,
						transform: `translateX(${i % 2 === 1 ? 6 : 0}rem) translateY(${-i * (props.layersOffset ?? 2)}rem)`,
						zIndex: `-${i * 10}`
					}}></div>
			)
		}
		return layers
	})()

	return (
		<div
			className="background"
			style={{ transform: `translateY(-${props.offset ?? 0}rem)` }}
			ref={refs.background}>
			{layerElements}
		</div>
	)
}
