import React from 'react'

import useRefs from '../../../context/refsContext'

import './Track.scss'

export default function Track() {
	const refs = useRefs()
	return (
		<div
			ref={refs.track}
			className="track"></div>
	)
}
