import anime, { AnimeInstance } from 'animejs'
import EventEmitter from 'events'

import { objectToArray } from './utils'
import { getRandomStyle } from './utils/styles'

import { Refs } from './context/refsContext.type'
import { Station } from './context/stationContext.type'

export const eventEmitter = new EventEmitter()

let initiated = false
const easeInParams = {
	anim1: 0.42,
	time1: 0,
	anim2: 0.5,
	time2: 0.5
}
const easeIn = `cubicBezier(${easeInParams.anim1}, ${easeInParams.time1}, ${easeInParams.anim2}, ${easeInParams.time2})`
const easeOut = `cubicBezier(
  ${easeInParams.anim2},
  ${easeInParams.time2},
  ${1 - easeInParams.anim1},
  ${1 - easeInParams.time1})`

export async function init(refs: Refs, stations: Station[]) {
	if (initiated) return
	initiated = true

	const { user, station, train, content, trees, track, stationModal, background } = Object.keys(refs).reduce<{
		[key: string]: HTMLDivElement | null
	}>((obj, ref) => ({ ...obj, [ref]: refs[ref as keyof typeof refs].current }), {})
	const stationWrapper: HTMLDivElement | null = station?.querySelector('.station-wrapper') ?? null
	const contentWidth = content ? `${content.clientWidth}px` : '0px'

	restartElements()

	const userTimeline = anime.timeline({
		targets: user,
		easing: 'easeInOutSine'
	})
	await userTimeline
		.add({ delay: 1500, duration: 4000, left: ['-200%', '54%'] })
		.add({ duration: 1000, endDelay: 1000, bottom: [0, '40%'] }).finished

	// Repeats cycle till stations end
	for (let i = 0; i < stations.length; i++) {
		travelStage(i > 0, i <= stations.length)
		await onNextStation()
	}
	await travelStage()
	confetti()

	async function onNextStation(): Promise<void> {
		return new Promise((resolve) => eventEmitter.once('next-station', resolve))
	}
	// Performs travel stage
	async function travelStage(stationIn: boolean = true, stationOut = true): Promise<void> {
		const timeline = anime.timeline()

		restartElements()

		speedUpAnim(stationIn)

		treeAnim()

		slowDownAnim(stationOut)

		return timeline.finished

		// Performs tree animation
		async function treeAnim(): Promise<void> {
			const { treeAnimParams, trackAnimParams, vehicleAnimParams } = anims()
			let trackAnim: AnimeInstance
			let vehicleAnim: AnimeInstance

			timeline.add({
				...treeAnimParams,
				begin: () => {
					// Starts track and vehicle movement
					trackAnim = anime({ ...trackAnimParams, easing: 'linear', loop: true })
					vehicleAnim = anime(vehicleAnimParams)
				},
				complete: () => {
					// Stops track and vehicle movement when they reach end of their loops
					if (trackAnim) trackAnim.loopComplete = () => trackAnim.pause()
					if (vehicleAnim) vehicleAnim.loopComplete = () => vehicleAnim.pause()
				}
			})
		}

		// Vehicle speed up animation
		async function speedUpAnim(stationOut: boolean = true): Promise<void> {
			const { trackVariableAnimParams } = anims()

			timeline.add({
				...trackVariableAnimParams,
				easing: easeIn
			})
			if (stationOut) stationOutAnim()

			// Station moves out of the screen
			async function stationOutAnim(): Promise<void> {
				const { stationAnimParams, trackVariableAnimParams, stationModalAnimParams } = anims()
				timeline.add({ ...stationModalAnimParams, top: ['5%', '-100%'] }, `-=${trackVariableAnimParams.duration}`).add(
					{
						...stationAnimParams,
						easing: easeIn,
						left: ['0', '100%'],
						complete: () => eventEmitter.emit('station-hidden')
					},
					`-=${trackVariableAnimParams.duration}`
				)
			}
		}

		// Vehicle slow down animation
		async function slowDownAnim(stationIn: boolean = true): Promise<void> {
			const { trackVariableAnimParams } = anims()
			timeline.add({
				...trackVariableAnimParams,
				easing: easeOut
			})
			if (stationIn) stationInAnim()

			// Station moves in to the screen
			async function stationInAnim(): Promise<void> {
				const { stationAnimParams, trackVariableAnimParams, stationModalAnimParams } = anims()

				timeline
					.add(
						{
							...stationAnimParams,
							easing: easeOut,
							left: ['-100%', '0'],
							begin: () => eventEmitter.emit('station-shown')
						},
						`-=${trackVariableAnimParams.duration}`
					)
					.add({ ...stationModalAnimParams, top: ['-100%', '5%'] }, `-=${stationModalAnimParams.duration}`)
			}
		}
	}

	function restartElements() {
		const treeElements = trees?.children ? objectToArray(trees?.children) : []
		// Moves all trees to left side outside of the screen
		treeElements.forEach((tree) => {
			tree.style.left = '-20%'
		})
		// Resizes trees wrapper's  width to the same width as content wrapper
		if (trees && content && station) {
			trees.style.width = contentWidth
			station.style.width = contentWidth
		}
		// Moves station to left side of the screen but not in the view
		if (stationWrapper) stationWrapper.style.left = '-100%'
		if (stationModal) stationModal.style.top = '-100%'
	}

	function anims() {
		const trackAnimDuration = 7000
		const treeAnimDuration = ((trackAnimDuration * (track?.clientWidth ?? 1)) / (trees?.clientWidth ?? 1)) * 0.3

		const carriages = content?.querySelectorAll<HTMLDivElement>('.carriage')
		const vehicleElements = [train, ...(carriages ? [...carriages] : [])]
		const treeElements = trees?.children ? objectToArray(trees?.children) : []

		// Animations params
		const trackAnimParams = {
			targets: [track, background],
			duration: trackAnimDuration,
			translateX: ['0', '10%']
		}
		const trackVariableAnimParams = {
			...trackAnimParams,
			duration: trackAnimParams.duration * 1.25
		}
		const stationAnimParams = {
			targets: stationWrapper,
			duration: trackVariableAnimParams.duration
		}
		const treeAnimParams = {
			targets: treeElements,
			duration: treeAnimDuration,
			easing: 'linear',
			delay: anime.stagger(treeAnimDuration / 15),
			keyframes: [{ left: '-20%' }, { left: '120%' }]
		}
		const vehicleAnimParams = {
			targets: vehicleElements,
			easing: 'easeInOutSine',
			duration: 3500,
			loop: true,
			direction: 'alternate',
			keyframes: [{ translateX: 10 }, { translateX: -20 }]
		}
		const stationModalAnimParams = { targets: stationModal, duration: 3000, easing: 'easeInOutSine' }

		return {
			trackAnimParams,
			trackVariableAnimParams,
			stationAnimParams,
			treeAnimParams,
			vehicleAnimParams,
			stationModalAnimParams
		}
	}

	async function confetti(elements: number = 200): Promise<void> {
		const confettiElements = []
		for (let i = 0; i < elements; i++) {
			const div = document.createElement('div')
			div.style.position = 'fixed'
			div.style.zIndex = '1000'
			div.style.left = '50%'
			div.style.bottom = '0'
			div.style.width = '3rem'
			div.style.height = '4rem'
			const background = getRandomStyle().background as string
			if (background) div.style.backgroundColor = background

			document.querySelector('#root')?.append(div)
			confettiElements.push(div)
		}
		const anim = anime({
			targets: confettiElements,
			left: () => ['50%', anime.random(5, 95) + '%', '50%'],
			bottom: () => ['-100%', anime.random(5, 95) + '%', 0],
			scale: [1, 0],
			rotate: () => anime.random(-360, 360),
			easing: () => `spring(1, 100, 50, ${anime.random(0, 10)})`,
			delay: () => anime.random(0, 1000)
		})

		await anim.finished

		confettiElements.forEach((element) => element.remove())
	}
}

export const itemAppearance = {
	easeOut: (targets: HTMLElement | HTMLElement[]) => ({
		targets,
		duration: 1000,
		easing: easeIn,
		translateY: ['0', '-100%'],
		opacity: [1, 0]
	}),
	easeIn: (targets: HTMLElement | HTMLElement[]) => ({
		targets,
		duration: 1000,
		easing: easeOut,
		translateY: ['-100%', '0'],
		opacity: [0, 1]
	})
}
