import React from 'react'

import useRefs from '../../context/refsContext'
import useStation from '../../context/stationContext'

import Train from './Vehicle/Train'
import User from './User/User'
import Track from './Objects/Track'
import Carriage from './Vehicle/Carriage'
import Background from './Objects/Background'

import './ContentWrapper.scss'

export default function ContentWrapper() {
	const refs = useRefs()
	const { stations } = useStation()

	return (
		<div
			className="content-wrapper"
			ref={refs.content}>
			<Train>
				<User />
			</Train>
			{stations.map((station) => (
				<Carriage
					key={station.carriageId}
					carriageId={station.carriageId}
					name={station.stationName}
				/>
			))}
			<Track />
			<Background
				layers={3}
				brightnessChange={4}
				layersOffset={3}
			/>
		</div>
	)
}
