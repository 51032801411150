export function objectToArray<T = any>(obj: { [key: string]: any }): T[] {
	return Object.keys(obj).map((key) => obj[key])
}
export function checkObjectsProps<T extends object>(obj: T, checkFunc?: (prop: keyof T, value: any) => boolean): boolean {
	const keys = Object.keys(obj)
	const values = Object.values(obj)
	return (
		values.filter((value, i) => {
			if (checkFunc) return checkFunc(keys[i] as keyof T, Object.values(obj)[i])
			else return !value
		}).length === 0
	)
}
export function setInArray<Type>(array: Type[], index: number, item: (item: Type) => Type): Type[] {
	const currentItem = array[index]
	return [...array.slice(0, index), item(currentItem), ...array.slice(index + 1, array.length)]
}

export function kebabCase(str: string) {
	const regex = new RegExp(/[A-Z]/g)
	return str.replace(regex, (v) => `-${v.toLowerCase()}`)
}