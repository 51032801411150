import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import { templateContentRef } from '../components/UI/Template'

const paddingVertical = 50
const paddingHorizontal = 50

export async function downloadResult() {
	try {
		const template = templateContentRef.current
		if (!template) return

		template.style.display = 'block'
		const canvas = await html2canvas(template)
		template.style.display = 'none'

		const doc = new jsPDF({
			unit: 'px',
			orientation: 'landscape',
			format: [canvas.width + paddingHorizontal * 2, canvas.height + paddingVertical * 2],
			compress: true
		})
		doc.addImage(canvas, 'PNG', paddingHorizontal, paddingVertical, canvas.width, canvas.height)
		doc.save('Your-Travel.pdf')
	} catch (error) {
		console.error(error)
		return
	}
}
