import React, { useContext } from 'react'

import { UserContext } from './userContext.type'

export const userContext = React.createContext<UserContext>({
	userIsSet: false,
	name: '',
	setName: () => {}
})

export default function useUser() {
	return useContext(userContext)
}
