import React from 'react'

import { kebabCase } from '.'

import { Style, Styles } from './styles.type'

const STYLES: Styles[] = [
	{ background: '#dc2626', color: '#ffffff' },
	{ background: '#ea580c', color: '#ffffff' },
	{ background: '#16a34a', color: '#ffffff' },
	{ background: '#14b8a6', color: '#ffffff' },
	{ background: '#22d3ee', color: '#ffffff' },
	{ background: '#0ea5e9', color: '#ffffff' },
	{ background: '#2563eb', color: '#ffffff' },
	{ background: '#6366f1', color: '#ffffff' },
	{ background: '#8b5cf6', color: '#ffffff' },
	{ background: '#a855f7', color: '#ffffff' },
	{ background: '#d946ef', color: '#ffffff' },
	{ background: '#f43f5e', color: '#ffffff' }
]
const stylesObject: Style[] = STYLES.map((style, id) => ({ id, used: 0, style: style }))

export function getRandomStyle(): Styles {
	// Gets minimum used style
	const min = Math.min(...stylesObject.map((style) => style.used))
	// Sorts colors by usage and shows only these which have been used the least
	const styles = stylesObject.sort((a, b) => a.used - b.used).filter((style) => style.used === min)

	// Gets random style from sorted filtered styles
	const randomStyle = styles[Math.floor(Math.random() * styles.length)]

	// Increases usage value in whole styles object
	const style = stylesObject.find((style) => style.id === randomStyle.id)
	if (style) style.used++

	return randomStyle.style
}
export function invertColor(hexColor: string): string | null {
	if (!hexColor.includes('#')) {
		return null
	}
	let hex: string = hexColor.replace('#', '')
	if (hex.length === 3) {
		hex = hex
			.split('')
			.map((number) => `${number}${number}`)
			.join('')
	}

	if (hex.length !== 6) {
		return null
	}
	hex = hex
		.split('')
		.reduce((acc, value, index) => acc + value + (index % 2 !== 0 ? ' ' : ''), '')
		.trim()

	const rgbColors = hex.split(' ').map((value) => parseInt(value, 16))
	const invertedHex = rgbColors.reduce((acc, color) => {
		return acc + (255 - color).toString(16).padStart(2, '0')
	}, '#')
	return invertedHex
}

export function convertStyles<T extends object = React.CSSProperties>(styles: T): string {
	const values = Object.values(styles)
	return Object.keys(styles).reduce((accumulator, key, i) => {
		// Transform the key from camelCase to kebab-case
		const cssKey = kebabCase(key)
		// Remove ' in value
		const cssValue = values[i].replace("'", '')

		return `${accumulator}${cssKey}:${cssValue};`
	}, '')
}
