import React, { useContext } from 'react'

import { StationContext } from './stationContext.type'

export const stationContext = React.createContext<StationContext>({
	currentStationId: 0,
	stations: [],
	station: {
		stationId: '',
		stationName: '',
		stationItems: [],
		carriageId: ''
	},
	finished: false,
	nextStation: () => null,
	addStationItem: () => '',
	addCarriageItem: () => {},
	getCarriage: () => undefined
})

export default function useStation() {
	return useContext(stationContext)
}
