import React, { useEffect, useRef } from 'react'
import anime from 'animejs'

import { itemAppearance } from '../../../animation'

import { CarriageItemProps } from './CarriageItem.type'

import './CarriageItem.scss'

export default function CarriageItem(props: CarriageItemProps) {
	const itemRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (itemRef.current) anime(itemAppearance.easeIn(itemRef.current))
	}, [])
	return (
		<div
			ref={itemRef}
			className="carriage-item"
			style={props.style}></div>
	)
}
