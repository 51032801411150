import React, { useEffect, useState } from 'react'
import * as uuid from 'uuid'
import data from '../data/stations.json'

import { stationContext } from './stationContext'
import useCarriage from './carriageContext'
import { setInArray } from '../utils'
import { getRandomStyle } from '../utils/styles'

import { ChildrenProps } from './types'
import { Station } from './stationContext.type'
import { Carriage } from './carriageContext.type'

const stationItems: Station[] = data.map((station) => ({
	stationId: uuid.v4(),
	carriageId: uuid.v4(),
	stationName: station.stationName,
	stationItems: station.stationItems.map((item) => ({
		id: uuid.v4(),
		value: item.value,
		style: item.style ?? getRandomStyle()
	}))
}))

export default function StationContextWrapper(props: ChildrenProps) {
	const [currentStationId, setCurrentStationId] = useState<number>(0)
	const [finished, setFinished] = useState<boolean>(false)
	const [stations, setStations] = useState<Station[]>(stationItems)

	const { carriages, addItem, setCarriages } = useCarriage()

	useEffect(() => {
		setCarriages(
			stationItems.map((station) => ({
				carriageName: station.stationName,
				carriageId: station.carriageId,
				items: []
			}))
		)
	}, [setCarriages])

	function nextStation(): Station | null {
		setCurrentStationId((value) => value + 1)
		if (currentStationId + 1 >= stations.length) {
			setFinished(true)
			return null
		}
		return stations[currentStationId + 1]
	}
	function addStationItem(value: string): string {
		const id = uuid.v4()
		// Adds new item in the same place in the `stations` array
		const newStations = setInArray(stations, currentStationId, (station) => ({
			...station,
			stationItems: [...station.stationItems, { id, value, style: getRandomStyle() }]
		}))
		addCarriageItem(id, newStations[currentStationId])
		return id
	}
	function addCarriageItem(id: string, station: Station) {
		const item = station.stationItems.find((item) => item.id === id)

		addItem({ id, value: item?.value ?? '', style: item?.style ?? getRandomStyle() }, stations[currentStationId].carriageId)
		setStations((stations) => {
			return setInArray(stations, currentStationId, (station) => ({
				...station,
				stationItems: station.stationItems.filter((stationItem) => stationItem.id !== id)
			}))
		})
	}
	function getCarriage(id: string): Carriage | undefined {
		return carriages.find((carriage) => carriage.carriageId === id)
	}

	return (
		<stationContext.Provider
			value={{
				currentStationId,
				stations,
				station: stations[currentStationId] ?? null,
				finished,
				nextStation,
				addStationItem,
				addCarriageItem: (id: string) => {
					addCarriageItem(id, stations[currentStationId])
				},
				getCarriage
			}}>
			{props.children}
		</stationContext.Provider>
	)
}
